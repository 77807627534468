/**
 * Created by carlosyanes on 22/6/16.
 */

$(document).ready(function(){
    videoControls();
});

$(window).resize(function(){
    videoControls();
});

function videoControls(){
    if($(window).width() < 768)
        $(".myvideo").prop("controls",true);
    else
        $(".myvideo").prop("controls",false);
}


//add some controls
jQuery(function ($) {
    $('div.video-project').each(function () {
        var player = this;
        var flag_controls = false;
        var getSetCurrentTime = createGetSetHandler(

            function () {
                $('input.time-slider', player).prop('value', $.prop(this, 'currentTime'));
            }, function () {
                try {
                    $('video, audio', player).prop('currentTime', $.prop(this, 'value'));
                } catch (er) {}
            });

        $('video, audio', this).on('durationchange updateMediaState', function () {
            var duration = $.prop(this, 'duration');
            if (!duration) {
                return;
            }
            $('input.time-slider', player).prop({
                'max': duration,
                disabled: false
            });
            $('span.duration', player).text(duration);

        }).on('timeupdate', function () {
            $('span.current-time', player).text($.prop(this, 'currentTime'));

        }).on('timeupdate', getSetCurrentTime.get).bind('emptied', function () {
            $('input.time-slider', player).prop('disabled', true);
            $('span.current-time', player).text(0);

        }).on('play', function(){
            $('div.play', player).html('<span class="glyphicon glyphicon-pause"></span>');
            $(player).find('.img-cover').removeClass('show');
            $(player).find(".play-general").removeClass('show');

        }).on('pause', function(){
            $('div.play', player).html('<span class="glyphicon glyphicon-play"></span>');

        }).on('ended', function(){
            $('div.play', player).html('<span class="glyphicon glyphicon-play"></span>');
            $(player).find(".play-general").addClass('show');
            $(player).find('.img-cover').addClass('show');
            flag_controls = false;
        }).on('volumechange', function(){

            var muted = $.prop(this, 'muted');
            if(muted == true)
                $('div.muted', player).html('<span class="glyphicon glyphicon-volume-off"></span>');
            else
                $('div.muted', player).html('<span class="glyphicon glyphicon-volume-up"></span>');

        }).each(function () {
            if ($.prop(this, 'readyState') > $.prop(this, 'HAVE_NOTHING')) {
                $(this).triggerHandler('updateMediaState');
            }
        });



        $(this).find(".play-general").on("click", function(){
            $('video, audio', player)[0].play();
            if($(window).width()<768){
                $('video, audio', player).parent('.mediaplayer').css('z-index', 85);
            }

            flag_controls = true;
        });


        var i = null;
        $(player).on("mousemove", function() {
            if($(window).width()>767){
                var that = this;
                if(flag_controls){
                    $(that).find('.controls').addClass('show');
                    $('.navegation-proyectos').removeClass('hidding');
                    $(this).css('cursor', 'default');

                    clearTimeout(i);
                    i = setTimeout(function () {
                        $(that).find('.controls').removeClass('show');
                        $('.navegation-proyectos').addClass('hidding');
                        if(flag_controls)
                            $(this).css('cursor', 'none');

                    }, 2000);

                }

            }


        }).on("mouseleave", function() {
            clearTimeout(i);
            $(this).find('.controls').removeClass('show');
            if(flag_controls)
                $(this).css('cursor', 'none');
        });

        $('input.time-slider', player).on('input', getSetCurrentTime.set).prop('value', 0);


        $('.play', player).on('click', function () {

            if($('video, audio', player).prop('paused'))
                $('video, audio', player)[0].play();
            else
                $('video, audio', player)[0].pause();


        });

        $('.muted', player).on('click', function () {
            if($('video, audio', player).prop('muted')){

                $('video, audio', player).prop('muted', false);
            }

            else{
                $('video, audio', player).prop('muted', true);

            }

        });

        $('#info-proyectos').on('click', function(){
            $('video, audio', player)[0].pause();
        });

    });

});






//helper for createing throttled get/set functions (good to create time/volume-slider, which are used as getter and setter)

function createGetSetHandler(get, set) {
    var throttleTimer;
    var blockedTimer;
    var blocked;
    return {
        get: function () {
            if (blocked) {
                return;
            }
            return get.apply(this, arguments);
        },
        set: function () {
            clearTimeout(throttleTimer);
            clearTimeout(blockedTimer);

            var that = this;
            var args = arguments;
            blocked = true;
            throttleTimer = setTimeout(function () {
                set.apply(that, args);
                blockedTimer = setTimeout(function () {
                    blocked = false;
                }, 30);
            }, 0);
        }
    }
}