/**
 * Created by carlosyanes on 5/10/16.
 */

//data

var sections = JSON.parse($('meta[name="_sections"]').attr('content'));
var position = $('meta[name="_position"]').attr('content');

var last_position = $('meta[name="_position"]').attr('content');

var body = $('body');

var sub_child_menu_right_1 = $(".sub-child-menu-right-1");
var image_project = $('.image-project');
var member_card = $('.member-card');

var lang = $('html').attr('lang');

var menu_open = false;

var section_open_mobile = false;

if(position > 0)
    menu_open = true;

var know_more;


$(document).ready(function(){

    menuOpen();
    clickLink();
    goSectionListener();
    urlListener();
    scrollMenu();
    hideAndShowScroll();
    hoverProjects();
    clickMember();
    moreProjects();
    privatePost();
    pace();
    knowMore();

});

$(window).on("load", function() {
    $(".loading").removeClass('show');
    $(".pre-load").removeClass('pre-load');
});

$(window).resize(function(){

    hideAndShowScroll();

});

function menuOpen(){
    if(body.hasClass('menu-open'))
        $('.row-more').hide()

    $('.menu-btn').on('click', function(){

        clearTimeout(know_more);

        $('.row-more').fadeOut(400);

        $('.isotype').addClass('show');

        if(section_open_mobile && body.hasClass('member-open')){
            body.removeClass('member-open');
            member_card.fadeOut(200);
        } else if(section_open_mobile) {
            $('.child-menu-left').removeClass('hide-mobile');
            section_open_mobile = false;
            $('.menu-btn .line').removeClass('white');
            $('.isotype').removeClass('no-invert');

        } else {
            if(body.hasClass('member-open')){

                body.removeClass('member-open');
                member_card.fadeOut(200);

            } else if (body.hasClass('private-open')) {

                body.removeClass('private-open');
                $('.sub-child-menu-right-4 .text-start').toggleClass('scaleIn');

            } else {
                body.toggleClass('menu-open');

                /*if(menu_open){
                 position = 0;
                 console.log(position);
                 }*/

                if(position>0 && menu_open){
                    window.history.pushState('', document.title, '/'+lang );

                } else {
                    window.history.pushState('', '/'+sections[position], '/'+lang+'/'+sections[position]);
                    $(window).trigger('goSection');
                }

                menu_open = !menu_open;
            }
        }

    });
}

function clickLink(){
    $('.container-links .link').on('click', function(){

        var _this = $(this);

        window.history.pushState('', '/'+sections[_this.data('id')], '/'+lang+'/'+sections[_this.data('id')]);
        $(window).trigger('goSection');

        if($(window).width() < 768){
            $('.child-menu-left').addClass('hide-mobile');
            $('.menu-btn .line').addClass('white');
            $('.isotype').addClass('no-invert');
            section_open_mobile = true;
        }


    });
}

function goSectionListener(){

    $(window).on('goSection', function() {

        var pathname = window.location.pathname;

        var last_pathname = pathname.substr(pathname.lastIndexOf('/') + 1);

        position = sections.indexOf(last_pathname);

        goSection(position);

        if(position == 4 && body.hasClass('private-open')){
            $('.sub-child-menu-right-4 .text-start').toggleClass('scaleIn');
            body.removeClass('private-open')
        }


    });
}

function urlListener(){

    window.onpopstate = history.onpushstate = function(e) {

        var pathname = window.location.pathname;

        console.log(pathname);

        var last_pathname = pathname.substr(pathname.lastIndexOf('/') + 1);

        position = sections.indexOf(last_pathname);

        goSection(position);

    };
}

function goSection(_position){

    $('.container-links .link').not(".link-"+_position).removeClass('active');
    $(".link-"+_position).addClass('active');

    var percent = (-_position*100)+'px';

    /*$('.container-menu-sections').css({
        '-ms-transform': 'translate3d(0,0,'+ percent+')',
        '-webkit-transform': 'translate3d(0,0,'+ percent+')',
        'transform': 'translate3d(0,0,'+ percent+')'
    });*/

    $('.sub-child-menu-right').not('.sub-child-menu-right-'+_position).removeClass('scaleIn');

    $('.sub-child-menu-right-'+_position).not('.sub-child-menu-right-'+last_position).addClass('scaleIn');

    last_position = _position;


}


function scrollMenu(){

    $('#scrolldown').on({
        'mousedown': function () {
            sub_child_menu_right_1.animate({
                scrollTop: sub_child_menu_right_1[0].scrollHeight
            }, 1200);
        },
        'mouseup': function () {
            sub_child_menu_right_1.stop(true);
        }
    });
    $('#scrollup').on({
        'mousedown': function () {
            sub_child_menu_right_1.animate({
                scrollTop: 0
            }, 400);
        },
        'mouseup': function () {
            sub_child_menu_right_1.stop(true);
        }
    });
    sub_child_menu_right_1.on('scroll', function(){
        hideAndShowScroll();
    });

}

function hideAndShowScroll(){
    if(sub_child_menu_right_1.scrollTop() == 0)
        $('#scrollup').fadeOut(200);
    else
        $('#scrollup').fadeIn(200);

    if(sub_child_menu_right_1.scrollTop() + sub_child_menu_right_1.innerHeight() >= sub_child_menu_right_1[0].scrollHeight)
        $('#scrolldown').fadeOut(200);
    else
        $('#scrolldown').fadeIn(200);
}


function hoverProjects(){


    $('.menu .project').hover(function(){

        var image = 'url("'+$(this).data('image')+'")';

        image_project.css('background-image', image);

        image_project.addClass('show');

    }, function(){
        image_project.removeClass('show');
    })
}

function clickMember(){

    $('.member').on('click', function(){
        var image = 'url("'+$(this).data('image')+'")';
        var name = $(this).data('name');
        var job = $(this).data('job');
        var text = $(this).data('text');

        body.addClass('member-open');

        member_card.css('background-image', image);
        $('.member-card .name').html(name);
        $('.member-card .job').html(job);
        $('.member-card .text').html(text);
        member_card.fadeIn(200);
    });
}


function moreProjects() {

    $('.row-plus').on('click', function () {

        window.history.pushState('', '/'+sections[1], '/'+lang+'/'+sections[1]);
        $(window).trigger('goSection');

        if(body.hasClass('member-open')){
            body.removeClass('member-open');
            member_card.fadeOut(200);

        } else {
            body.toggleClass('menu-open');

            /*if(menu_open){
             position = 0;
             console.log(position);
             }*/

            if(position>0 && menu_open){
                window.history.pushState('', document.title, '/'+lang );

            } else {
                window.history.pushState('', '/'+sections[position], '/'+lang+'/'+sections[position]);
                $(window).trigger('goSection');
            }

            menu_open = !menu_open;
        }

    });

}


function privatePost(){
    $('.arrow').on('click', function(){
        var url = $(this).data('url');
        var pass = $('#private-pass').val();
        if(pass != ''){
            $.ajax({
                url:url,
                method:'POST',
                data:{
                    pass: pass
                }
            }).done(function(msg){
                body.addClass('private-open');
                $('.text-start').toggleClass('scaleIn');
                $('.text-start-2').html(msg);

            }).fail(function(jqXHR, textStatus){
                console.log(jqXHR, textStatus);
            })
        }

    });
}


/*function pace(){

    Pace.on('progress', function(p){
        var remain = 100 - p;
        $('.quad').css('width', p);
        $('.quad').css('height', p);
        if(remain <= 0){
            $('.loading-curtain').removeClass('show');
            setTimeout(function(){
                $('.loading-curtain').addClass('final-hide');
                setTimeout(function(){
                    $('.loading-curtain').fadeOut(400);
                }, 600)
            }, 2000)

        }

    })
}*/

function pace(){

    Pace.options = {
        restartOnRequestAfter: false,
        eventLag: true,
        elements: false,
        ajax:false,
        ignoreURLs: 'https://player.vimeo.com/external/247447132.hd.mp4?s=5af7173199c0b745895a75a509d17d7c8e3f8e34&profile_id=174'
    };



    Pace.on('progress', function(p) {
        var remain = 100 - p;
        $('.quad').css('width', p);
        $('.quad').css('height', p);
        if (remain <= 10) {
            $('.loading-curtain').removeClass('show');
            setTimeout(function(){
                $('.loading-curtain').fadeOut(800);
                $('.isotype').addClass('show');
                $('.row-plus').addClass('show');
                $('.play-play').addClass('showing');

            }, 2000);

        }

    });
}

function knowMore(){
    var know_more = setTimeout(function(){$('.know').fadeIn(2000)}, 5000);

    if(body.hasClass('menu-open'))
        clearTimeout(know_more);

    $('.know').click(function(){
        $('.row-more').fadeOut(400);
        $('.isotype').addClass('show');
        clearTimeout(know_more);

        body.addClass('menu-open');

        /*if(menu_open){
         position = 0;
         console.log(position);
         }*/

        if(position>0 && menu_open){
            window.history.pushState('', document.title, '/'+lang );

        } else {
            window.history.pushState('', '/'+sections[position], '/'+lang+'/'+sections[position]);
            $(window).trigger('goSection');
        }

        menu_open = !menu_open;
    })
}








