/**
 * Created by carlosyanes on 18/1/17.
 */

var video_home = $('#video');



var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

resizeVideo();

$(document).ready(function() {
    resizeVideo();
    videoHome();
});

$(window).resize(function(){
    resizeVideo();
});

/*$(window).load(function() {

});*/

function resizeVideo(){

    var _window = {
        height:$( window ).height(),
        width:$( window ).width()
    };

    if( _window.width/_window.height > 16/9 ){
        video_home.width(_window.width);
        video_home.height(_window.width*(9/16));
    } else {
        video_home.height(_window.height);
        video_home.width(_window.height*(16/9));
    }

}

function videoHome(){
    var video = document.getElementById('video');
    video.play();
    video.onplaying = function(){
        $('#video').addClass('show');
        if(video.paused){
            video.play();
            $('.muted').html('<i class="fa fa-volume-off"></i>');
        }
    };

    if ($('html').hasClass('touchevents')){
        /*video.muted = true;
        $('#video').prop('muted', true);
        setTimeout(function(){video.play();}, 100);
        setTimeout(function(){video.play();}, 300);
        setTimeout(function(){video.play();}, 500);
        setTimeout(function(){video.play();}, 800);
        setTimeout(function(){video.play();}, 1000);
        setTimeout(function(){video.play();}, 1500);

        $('.muted').html('<i class="fa fa-volume-off"></i>');*/
    }

    $('.play-play').click(function(){
        $('#video').addClass('show');
        video.muted = false;
        $('.muted').html('<i class="fa fa-volume-up"></i>');
        video.currentTime = 0;
        video.play();

        $('.play-play').fadeOut(600);
    });

    $('.muted').click(function(){

        var video_0 = $("#video");

        if( video_0.prop('muted') ){
            video_0.prop('muted', false);
            $(this).html('<i class="fa fa-volume-up"></i>');
        } else {
            video_0.prop('muted', true);
            $(this).html('<i class="fa fa-volume-off"></i>');
        }

    });


}





